import React, { useState } from 'react';
import { Box } from '@mui/material';

const Command = ({ sendCommand }) => {
  const [selectedCommand, setSelectedCommand] = useState('');
  const [commandInput, setCommandInput] = useState('');

  const handleCommandChange = (e) => {
    setSelectedCommand(e.target.value);
  };

  const handleInputChange = (e) => {
    setCommandInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendCommand();
    }
  };

  const handleSendCommand = () => {
    if (selectedCommand.trim() !== '' && commandInput.trim() !== '') {
      const fullCommand = `${selectedCommand} ${commandInput}`;
      sendCommand(fullCommand.trim());
      setSelectedCommand('');
      setCommandInput('');
    }
  };

  return (
    <Box className="command-container">
      <select className="command-select" value={selectedCommand} onChange={handleCommandChange}>
        <option value="">Command Server</option>
        <option value="say">Say</option>
        <option value="op">OP</option>
        <option value="deop">DEOP</option>
        <option value="quit">QUIT (EngineGame)</option>
      </select>
      <input 
        className="command-input"
        type="text"
        placeholder='Select your command and enter command. Press ENTER' 
        value={commandInput} 
        onChange={handleInputChange} 
        onKeyPress={handleKeyPress}
      />
    </Box>
  );
};

export default Command;
