import ServerListComponent from "./ServerListComponent";
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import colors from "assets/theme/base/colors";

function Servers() {
  const { gradients } = colors;
  const { cardContent } = gradients;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>

        <VuiBox mb={10.4}>
          <Grid container spacing="18px">
          <Grid item xs={15} lg={15} xl={15} justifyContent="center">
              <ServerListComponent />
            </Grid>
          </Grid>
        </VuiBox>
      </VuiBox>

    </DashboardLayout>
  );
}

export default Servers;
