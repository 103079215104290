import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import MailIcon from '@mui/icons-material/Mail';
import { Menu, MenuItem, Typography } from '@mui/material';
import axios from 'axios';
import './notif.css';

const NotificationComponent = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        // Obtenir le token JWT stocké dans les cookies
        const token = document.cookie.split('; ').find(row => row.startsWith('adp_session=')).split('=')[1];

        // Obtenir les détails de l'utilisateur et les notifications non lues
        if (token) {
            axios.get('https://api.mariabird.net/user-details', {
                headers: { 'Authorization': `Bearer ${token}` }
            })
            .then(response => {
                setUserId(response.data.id);

                // Maintenant, demandez les notifications non lues
                axios.get(`https://api.mariabird.net/api/services/notifications/unread?userId=${response.data.id}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                })
                .then(notifResponse => {
                    setNotifications(notifResponse.data);
                })
                .catch(error => {
                    console.error('Erreur lors de la récupération des notifications non lues', error);
                });

                // Connexion WebSocket
                const socket = io('https://gitws.mariabird.net/', {
                    query: { userId: response.data.id }
                });

                socket.on('newRelease', (data) => {
                    setNotifications(prevNotifications => [...prevNotifications, { id: prevNotifications.length + 1, message: data.message }]);
                });
                socket.on('newPush', (data) => {
                    setNotifications(prevNotifications => [...prevNotifications, { id: prevNotifications.length + 1, message: data.message }]);
                });

                return () => socket.disconnect();
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des détails de l’utilisateur', error);
            });
        }
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleNotificationClick = async (notificationId) => {
        try {
            // Appel API pour marquer la notification comme lue
            const response = await axios.post('https://api.mariabird.net/api/services/notifications/mark-read', {
                notificationId: notificationId,
                userId: userId // Assurez-vous que userId est défini dans votre état de composant
            });
    
            if (response.status === 200) {
                // Mettre à jour l'état des notifications pour retirer celle qui a été lue
                setNotifications(notifications.filter(notification => notification.id !== notificationId));
                handleClose();
            }
        } catch (error) {
            console.error('Erreur lors de la mise à jour de la notification', error);
        }
    };

    const handleClearAllNotifications = async () => {
        try {
            // Appel API pour marquer toutes les notifications comme lues
            const response = await axios.post('https://api.mariabird.net/api/services/notifications/mark-all-read', {
                userId: userId // Assurez-vous que userId est défini dans votre état de composant
            });
    
            if (response.status === 200) {
                // Mettre à jour l'état des notifications pour vider la liste
                setNotifications([]);
                handleClose();
            }
        } catch (error) {
            console.error('Erreur lors de la mise à jour des notifications', error);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const iconStyle = {
        color: notifications.length > 0 ? '#f0b232' : '#344767',
        animation: notifications.length > 0 ? 'jump 0.5s ease-in-out infinite' : 'none',
    };

    return (
        <div>
            <IconButton color="inherit" onClick={handleClick} style={iconStyle}>
                <Badge badgeContent={notifications.length} color="secondary">
                    <MailIcon />
                </Badge>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {notifications.map(notification => (
                    <MenuItem key={notification.id} onClick={() => handleNotificationClick(notification.id)}>
                        <Typography variant="inherit">{notification.message}</Typography>
                    </MenuItem>
                ))}
                <MenuItem onClick={handleClearAllNotifications}>Effacer toutes les notifications</MenuItem>
            </Menu>
        </div>
    );
};

export default NotificationComponent;

