import React, { useState, useEffect } from 'react';
import axios from 'axios';
// @mui material components
import Grid from "@mui/material/Grid";
//import Icon from "@mui/material/Icon";
//import { Card, LinearProgress, Stack } from "@mui/material";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
//import VuiTypography from "components/VuiTypography";
//import VuiProgress from "components/VuiProgress";

// Vision UI Dashboard React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from "examples/Footer";

import colors from "assets/theme/base/colors";

// Dashboard layout components

import OneProviderInvoiceCard from '../component/OneProviderInvoiceCard';

const OneProviderInvoiceDetails = () => {

  // Mettre en place les autres fonctions selon le besoin, par exemple fetchInvoiceDetails, payInvoice, etc.

  // ... Votre JSX pour afficher les données
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={10.4}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={5} xl={6} justifyContent="center">
              <OneProviderInvoiceCard />
            </Grid>
          </Grid>
        </VuiBox>
      </VuiBox>
    </DashboardLayout>
  );
  
};

export default OneProviderInvoiceDetails;