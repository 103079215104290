import React, { useState } from 'react';
import SimpleDialog from './SimpleDialog';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    startButton: {
      backgroundColor: '#4caf50', // Vert
      '&:hover': {
        backgroundColor: '#388e3c', // Vert foncé
      },
    },
    stopButton: {
      backgroundColor: '#f44336', // Rouge
      '&:hover': {
        backgroundColor: '#d32f2f', // Rouge foncé
      },
    },
    restartButton: {
      backgroundColor: '#1565c0', // Bleu foncé
      '&:hover': {
        backgroundColor: '#0d47a1', // Bleu plus foncé
      },
    },
    killButton: {
      backgroundColor: '#b71c1c', // Rouge foncé
      '&:hover': {
        backgroundColor: '#7f0000', // Rouge très foncé
      },
    },
  });

const PowerButton = ({ ws }) => {
    const sendCommand = (command) => {
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
            console.log(`Envoi de la commande: ${command}`);
            ws.current.send(JSON.stringify({ event: "set state", args: [command] }));
        } else {
            console.log("WebSocket n'est pas connecté.");
        }
    };

    return (
        <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
            <Button variant="outlined" color="primary" onClick={() => sendCommand('start')}>
                Start
            </Button>
            <Button variant="outlined" color="error" onClick={() => sendCommand('stop')}>
                Stop
            </Button>
            <Button variant="outlined" color="warning" onClick={() => sendCommand('restart')}>
                Restart
            </Button>
            <Button variant="outlined" color="error" onClick={() => sendCommand('kill')}>
                Kill
            </Button>
        </div>
    );
};

export default PowerButton;