import React, { useState, useEffect } from 'react';
import { Card, CircularProgress, Skeleton } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import io from 'socket.io-client';

const ServerStats = () => {
  const [cpuLoad, setCpuLoad] = useState(null);
  const [ramUsage, setRamUsage] = useState(null);
  const [cpuColor, setCpuColor] = useState('');
  const [ramColor, setRamColor] = useState('');
  const [prevCpuLoad, setPrevCpuLoad] = useState(null);
  const [prevRamUsage, setPrevRamUsage] = useState(null);
  const [cpuArrowDirection, setCpuArrowDirection] = useState('');
  const [ramArrowDirection, setRamArrowDirection] = useState('');

  useEffect(() => {
    const socket = io('https://wsstats.mariabird.net/');

    socket.on('serverStats', (data) => {
      setCpuLoad(data.cpuLoad);
      setRamUsage(data.ramUsage);

      // Color CPU
      if (data.cpuLoad >= 0 && data.cpuLoad <= 50) {
        setCpuColor('success');
      } else if (data.cpuLoad > 50 && data.cpuLoad <= 70) {
        setCpuColor('warning');
      } else if (data.cpuLoad > 70 && data.cpuLoad <= 100) {
        setCpuColor('error');
      }

      // Color RAM
      if (data.ramUsage >= 0 && data.ramUsage <= 50) {
        setRamColor('success');
      } else if (data.ramUsage > 50 && data.ramUsage <= 70) {
        setRamColor('warning');
      } else if (data.ramUsage > 70 && data.ramUsage <= 100) {
        setRamColor('error');
      }

      setPrevCpuLoad(cpuLoad);
      setPrevRamUsage(ramUsage);

      if (prevCpuLoad !== null) {
        setCpuArrowDirection(data.cpuLoad > prevCpuLoad ? 'up' : 'down');
      }

      if (prevRamUsage !== null) {
        setRamArrowDirection(data.ramUsage > prevRamUsage ? 'up' : 'down');
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [cpuLoad, ramUsage, prevCpuLoad, prevRamUsage]);

  const renderStats = (value, label, color, arrowDirection) => (
    <VuiBox sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
      {value !== null ? (
        <>
          <CircularProgress variant="determinate" value={value} size={170} color={color} sx={{ transform: 'rotate(-90deg)' }} />
          <VuiTypography color={color} variant='h3' mt='12px'>
            {value.toFixed(2)}%
          </VuiTypography>
          {arrowDirection !== '' && (
            <VuiBox sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none' }}>
              {arrowDirection === 'up' ? <ArrowUpwardIcon color={color} /> : <ArrowDownwardIcon color={color} />}
            </VuiBox>
          )}
        </>
      ) : (
        <>
          <Skeleton variant="circular" width={170} height={170} animation="wave" />
          <Skeleton variant="text" width={60} sx={{ mt: '12px' }} animation="wave" />
        </>
      )}
      <VuiTypography color='text' variant='caption' fontWeight='regular'>
        {label}
      </VuiTypography>
    </VuiBox>
  );
  
  return (
    <Card sx={{ height: '400px', width: '700px', position: 'absolute', top: 200, right: 150, zIndex: 1000 }}>
      <VuiBox display='flex' flexDirection='column' justifyContent='space-around' alignItems='center' sx={{ height: '100%' }}>
        <VuiTypography variant='lg' color='white' fontWeight='bold' mt='20px' mb='20px'>
          Server MariaBird Statistics
        </VuiTypography>
        <VuiBox sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', px: '20px' }}>
          {renderStats(cpuLoad, 'CPU Load', cpuColor, cpuArrowDirection)}
          {renderStats(ramUsage, 'RAM Usage', ramColor, ramArrowDirection)}
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default ServerStats;

