import { useState, useEffect } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import routes from "routes";
import { useVisionUIController, setMiniSidenav, setOpenConfigurator } from "context";
import { useAuth } from "context/AuthContext";
import Admin from "layouts/admin";
import AlertComponent from "components/Alert";
import AlertGitComponent from "components/Alert/AlertGit";
import { AlertProvider } from "context/AlertContext";

const App = () => {
  const { isAuthenticated, isLoading } = useAuth();
  const location = useLocation();

  // Vérifiez isLoading avant tout autre Hook
  if (isLoading) {
    return <div>Chargement...</div>; // Affichage pendant le chargement
  }

  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);


  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location.pathname]);

  const getRoutes = (allRoutes) => allRoutes.map((route) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }
    if (route.route) {
      return <Route exact path={route.route} component={route.component} key={route.key} />;
    }
    return null;
  });

  return (
    <ThemeProvider theme={theme}>
      <AlertProvider>
     <AlertComponent />
     <AlertGitComponent />
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand=""
            brandName="ADP V4"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
        </>
      )}
      <Switch>
        <Route exact path="/login" component={SignIn} />
        <Route exact path="/register" component={SignUp} />
        {isAuthenticated ? getRoutes(routes) : <Redirect to="/login" />}
        <Redirect from="*" to="/" />
        <Route path="/admin" render={() => {
        const user = useContext(AuthContext).user;
        return user && user.isAdmin ? <Admin /> : <Redirect to="/" />;
        }} />
      </Switch>
      </AlertProvider>
    </ThemeProvider>
  );
};

export default App;
