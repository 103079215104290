import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { Button } from '@mui/material';
import md5 from 'blueimp-md5';
import './ListUser.css';

const ListUser = () => {
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState('ADP');

  // Fonction pour obtenir l'URL de l'avatar à partir de l'adresse e-mail
  const getGravatarUrl = (email) => {
    const normalizedEmail = email.trim().toLowerCase();
    const md5Hash = md5(normalizedEmail);
    return `https://www.gravatar.com/avatar/${md5Hash}?s=40`;
  };

  // Fonction pour formater la date en français
  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', options).replace(/(\d+)\/(\d+)\/(\d+),/, '$3/$2/$1');
  };

  // Fonction pour générer l'URL d'édition de l'utilisateur
  const getEditUserUrl = (userId) => {
    return `/!/wl/admin/Users/edit/${filter.toLowerCase()}/${userId}`;
  };

  useEffect(() => {
    const fetchUsers = async () => {
      let url = '';
      let apiKey = '';
      if (filter === 'MBC') {
        url = 'https://mariabird.net/api/application/users';
        apiKey = 'ptla_17T1VO9epJNWSL9H39j7SEf9dT4qWepFAWuHre3UPZk';
      } else if (filter === 'ADP') {
        url = 'https://api.mariabird.net/allusers';
      }
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${apiKey}`
          }
        });
        if (response.data) {
          if (filter === 'MBC' && response.data.object === 'list' && response.data.data) {
            setUsers(response.data.data.map(user => ({
              id: user.attributes.id,
              name: user.attributes.username,
              email: user.attributes.email,
              root_admin: user.attributes.root_admin,
              dashboard: user.attributes.dashboard,
              '2fa': user.attributes['2fa'],
              created_at: formatDate(user.attributes.created_at),
              updated_at: formatDate(user.attributes.updated_at),
            })));
          } else if (filter === 'ADP') {
            setUsers(response.data);
          }
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, [filter]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <Button onClick={() => setFilter('ADP')}>ADPV4</Button>
        <Button onClick={() => setFilter('MBC')}>MariaBird Classic</Button>
      </div>
      <div className="user-table-container">
        <table className="user-table">
          <thead>
            <tr>
              <th>Avatar</th>
              <th>USER ID</th>
              <th>NAME</th>
              <th>EMAIL</th>
              {filter === 'MBC' && (
                <>
                  <th>Admin</th>
                  <th>Dashboard</th>
                  <th>2FA</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                </>
              )}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td>
                  <img src={getGravatarUrl(user.email)} alt="Avatar" />
                </td>
                <td>{user.id}</td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                {filter === 'MBC' && (
                  <>
                    <td>{user.root_admin ? 'Yes' : 'No'}</td>
                    <td>{user.dashboard}</td>
                    <td>{user['2fa'] ? 'Enabled' : 'Disabled'}</td>
                    <td>{user.created_at}</td>
                    <td>{user.updated_at}</td>
                  </>
                )}
                <td>
                  <Button variant="contained" color="primary" href={getEditUserUrl(user.id)}>
                    Edit
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </DashboardLayout>
  );
};

export default ListUser;
