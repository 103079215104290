import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import io from 'socket.io-client';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertGitComponent = () => {
  const [open, setOpen] = useState(false);
  const [gitMessage, setGitMessage] = useState('');

  useEffect(() => {
    const socket = io('https://gitws.mariabird.net/');

    socket.on('newPush', (data) => {
        console.log('Données reçues:', data);
        setGitMessage(data.message);
        setOpen(true);
      });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
        {gitMessage}
      </Alert>
    </Snackbar>
  );
};

export default AlertGitComponent;