import React, { useState, useEffect } from 'react';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent, TimelineDot } from '@mui/lab';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { useAuth } from 'context/AuthContext';
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "layouts/profile/components/Header";
import { TbApi } from "react-icons/tb";
import { CiLogin } from "react-icons/ci";
import { GrValidate } from "react-icons/gr";
function ProfileActivity() {
  const [activityData, setActivityData] = useState([]);
  const { userDetails } = useAuth();

  useEffect(() => {
    const fetchActivityData = async () => {
      const response = await fetch('https://mariabird.net/api/client/account/activity', {
        headers: {
          'Authorization': `Bearer ${userDetails?.identifier}`,
        }
      });
      const data = await response.json();
      const sortedData = data.data.sort((a, b) => 
        new Date(b.attributes.timestamp) - new Date(a.attributes.timestamp)
      );
      setActivityData(sortedData);
    };

    if (userDetails?.identifier) {
      fetchActivityData();
    }
  }, [userDetails]);

  const activityIcon = (event) => {
    switch (event) {
      case "auth:success":
        return <CiLogin />;
      case "auth:checkpoint":
        return <GrValidate />;
      case "user:api-key.create":
        return <TbApi />;
      case "user:api-key.delete":
        return <TbApi />;

      default:
        return <RepeatIcon />;
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Les mois sont indexés à partir de 0
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;
  };

  return (
    <DashboardLayout>
      <Header />
      <VuiBox p={3}>
        <Card elevation={3}>
          <VuiBox p={2}>
            <Timeline position="alternate">
              {activityData.map((activity, index) => (
                <TimelineItem key={index}>
                  <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                  >
                    {formatDate(activity.attributes.timestamp)}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot>
                      {activityIcon(activity.attributes.event)}
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                      {activity.attributes.event.replace(/_/g, ' ')}
                    </Typography>
                    <Typography>IP: {activity.attributes.ip}</Typography>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </VuiBox>
        </Card>
      </VuiBox>
    </DashboardLayout>
  );
}

export default ProfileActivity;
