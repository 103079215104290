import { useAuth } from 'context/AuthContext';
import Grid from "@mui/material/Grid";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
//import Footer from "examples/Footer";
// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";
import ProfileUpdater from 'layouts/profile/components/ProfileUpdater';
function Overview() {
  const { userDetails } = useAuth();
  if (!userDetails) {
    return <div>Chargement...</div>;
  }
  return (
    <DashboardLayout>
      <Header />
      <VuiBox mt={5} mb={20}>
        <Grid
          container
          spacing={3}
          sx={({ breakpoints }) => ({
            [breakpoints.only("xl")]: {
              gridTemplateColumns: "repeat(2, 1fr)",
            },
          })}
        >
          <Grid
            item
            xs={12}
            xl={4}
            xxl={3}
            sx={({ breakpoints }) => ({
              minHeight: "400px",
              [breakpoints.only("xl")]: {
                gridArea: "1 / 1 / 2 / 2",
              },
            })}
          >
            <ProfileInfoCard
              title="profile information"
              description="Aucune Description"
              info={{
                Admin: userDetails.isAdmin,
                Username: userDetails.name,
                FirstName: userDetails.firstname,
                LastName: userDetails.lastname,
                email: userDetails.email,
                location: "France",
              }}
              social={[
              ]}
            />
          </Grid>
          <Grid item xs={12} xl={3} height="100%">
            <PlatformSettings />
          </Grid>
          <Grid item xl={6} height="100%">
           <ProfileUpdater />
          </Grid>
        </Grid>
      </VuiBox>
    </DashboardLayout>
  );
}

export default Overview;
