// UpdateIndicator.js
import React from "react";
import { MdOutlineBrowserUpdated } from "react-icons/md";

const UpdateIndicator = ({ isUpdateAvailable, size }) => {
  return (
    <div>
      {isUpdateAvailable && <MdOutlineBrowserUpdated style={{ color: "green", fontSize: size }} />}
    </div>
  );
};

export default UpdateIndicator;
