import React, { useState, useEffect } from 'react';
import { useAuth } from 'context/AuthContext';
import { useHistory } from 'react-router-dom';
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import VuiAvatar from "components/VuiAvatar";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { IoCube, IoBuild } from "react-icons/io5";
import { RxActivityLog } from "react-icons/rx";
import { RiAdminLine } from "react-icons/ri";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import md5 from 'blueimp-md5';
import breakpoints from "assets/theme/base/breakpoints";

function Header() {
  const { userDetails } = useAuth();
  const history = useHistory();
  const avatarUrl = userDetails ? `https://www.gravatar.com/avatar/${md5(userDetails.email.trim().toLowerCase())}?s=40` : 'assets/images/avatar-simmmple.png';
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    function handleTabsOrientation() {
      setTabsOrientation(window.innerWidth < breakpoints.values.lg ? "vertical" : "horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  useEffect(() => {
    const path = window.location.pathname;
    const tabMap = { "/profile": 0, "/profile/api": 1, "/profile/activity": 2, "/!/wl/admin": 3 };
    setTabValue(tabMap[path] || 0);
  }, [setTabValue]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    const urlMap = ["/profile", "/profile/api", "/profile/activity", "/!/wl/admin"];
    history.push(urlMap[newValue]);
  };

  return (
    <VuiBox position="relative">
      <DashboardNavbar light />
      <Card sx={{ px: 3, mt: 2 }}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} md={1.7} lg={1.5} xl={1.2} xxl={0.8} display="flex">
            <VuiAvatar src={avatarUrl} alt="profile-image" variant="rounded" size="xl" shadow="sm" />
          </Grid>
          <Grid item xs={12} md={4.3} lg={4} xl={3.8} xxl={7}>
            <VuiBox height="100%" mt={0.5} lineHeight={1} display="flex" flexDirection="column">
              <VuiTypography variant="lg" color="white" fontWeight="bold">
              {userDetails ? userDetails.name : 'Chargement...'}
              </VuiTypography>
            </VuiBox>
          </Grid>
          <Grid item xs={12} md={7} lg={6.5} xl={6} xxl={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue} sx={{ background: "transparent", display: "flex", justifyContent: "flex-end" }}>
                <Tab label="OVERVIEW" icon={<IoCube color="white" size="16px" />} />
                <Tab label="API" icon={<IoBuild color="white" size="16px" />} />
                <Tab label="ACTIVITY" icon={<RxActivityLog color="white" size="16px" />} />
                <Tab label="ADMIN" icon={<RiAdminLine color="white" size="16px" />} />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Card>
    </VuiBox>
  );
}

export default Header;
