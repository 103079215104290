
import { useState, useEffect } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import { LuArrowDownToLine } from "react-icons/lu";

import UpdateIndicator from "components/Update";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Vision UI Dashboard React context
import {
  useVisionUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import NotificationComponent from "components/Notification";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const { logout } = useAuth();

  const [searchQuery, setSearchQuery] = useState('');
  const history = useHistory();

  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);

  const serverMap = {
    'home': '/',
    'Home': '/',
    'omniopolis': '2e1edc89',
    'PO3myth': 'afd96db3',
    'All the Mods 9 - ATM9': '00001fe7',
    'Frost RP - FIVEM': '05e56d99',
    'terrafirmagreg': 'a7199aab',
    'Genesis': '3bcdcb3d',
    'Prozer Vanilla': 'd5e0f719',
    'Ragnamod VII': '1dc4ffdf',
    'CAE': '7c609a17',
  };

  // Fonction pour gérer la soumission de la recherche
  const handleSearch = (event) => {
    event.preventDefault();
    const serverUID = serverMap[searchQuery];
    if (serverUID) {
      history.push(`/server/${serverUID}`); // utilisez push pour naviguer
    } else {
      //console.log('Serveur non trouvé');
      // Vous pouvez ajouter une gestion d'erreur ou une notification ici
    }
  };;

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  useEffect(() => {
    const checkForUpdate = async () => {
      try {
        const response = await fetch("https://api.mariabird.net/CheckUpdate");
        const data = await response.json();
        setIsUpdateAvailable(data.isUpdateAvailable);
      } catch (error) {
        console.error("Erreur lors de la vérification de la mise à jour:", error);
      }
    };

    checkForUpdate();
    const interval = setInterval(() => {
      checkForUpdate();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const updateAvailable = localStorage.getItem("isUpdateAvailable") === "true";
    setIsUpdateAvailable(updateAvailable);
  }, [])

  const handleRefreshClick = () => {
    // Mettre à jour l'état de mise à jour dans le localStorage et dans l'état du composant
    localStorage.setItem("isUpdateAvailable", "false");
    setIsUpdateAvailable(false);
    // Ajouter ici la logique pour rafraîchir votre application
    window.location.reload();
  };
  

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <VuiBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </VuiBox>
        {isMini ? null : (
          <VuiBox sx={(theme) => navbarRow(theme, { isMini })}>
            <VuiBox pr={1}>
            <form onSubmit={handleSearch}>
        <VuiInput
          placeholder="Nom Du Server"
          icon={{ component: "search", direction: "left" }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={(theme) => ({
            // ...styles existants
          })}
        />
      </form>
            </VuiBox>
            <NotificationComponent/>
            <UpdateIndicator isUpdateAvailable={isUpdateAvailable} size={30} onClick={handleRefreshClick} />
            <VuiBox color={light ? "white" : "inherit"}>
              <Link to="/profile">
                <IconButton sx={navbarIconButton} size="small">
                  <Icon
                    sx={({ palette: { dark, white } }) => ({
                      color: light ? white.main : dark.main,
                    })}
                  >
                    account_circle
                  </Icon>
                  <VuiTypography
                    variant="button"
                    fontWeight="medium"
                    color={light ? "white" : "dark"}
                  >
                    Account
                  </VuiTypography>
                </IconButton>
              </Link>
              <IconButton
                size="small"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={"text-white"}>{miniSidenav ? "menu_open" : "menu"}</Icon>
              </IconButton>
              {renderMenu()}
            </VuiBox>
            <Link to="/logout">
            <IconButton sx={navbarIconButton} size="small">
            <Icon
              sx={({ palette: { dark, white } }) => ({
                color: light ? white.main : dark.main,
              })}
            >
              logout
            </Icon>
            <VuiTypography
              variant="button"
              fontWeight="medium"
              color={light ? "white" : "dark"}
            >
              Logout
            </VuiTypography>
          </IconButton>
          </Link>
          </VuiBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
