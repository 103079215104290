
import { useState, useEffect } from "react";
import { useAuth } from "context/AuthContext";

// react-router-dom components
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgSignIn from "assets/images/signInImage.png";

function SignIn() {
  const { login } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const [error, setError] = useState('');
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    let timer;
    if (showError) {
      timer = setTimeout(() => {
        setShowError(false); // Cela cachera la notification après 3 secondes
      }, 15000);
    }
    return () => clearTimeout(timer);
  }, [showError]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
  
    try {
      const response = await fetch('https://api.mariabird.net/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
  
      if (response.ok) {
        document.cookie = `adp_session=${data.token}; path=/; max-age=3600; secure; samesite=strict`; // Stocker le token dans un cookie
        login(data.token);
        history.push('/servers');
      } else {

        console.log('Échec de la connexion');
        setError(data.message || 'Vérifiez vos identifiants et réessayez.');
      }
    } catch (error) {
      console.error('Vérifiez vos identifiants et réessayez. ', error);
      setError('Vérifiez vos identifiants et réessayez. ');
    }
  };
  
  
  // Error Style //
  const errorStyles = {
    fontSize: '0.8em',
    position: 'absolute',
    top: '15%',
    right: '26px',
    backgroundColor: 'red',
    color: 'white',
    padding: '10px',
    borderRadius: '5px',
    zIndex: 1000,
    maxWidth: '300px',
  };
  
  const [rememberMe, setRememberMe] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <CoverLayout
      title="Bienvenue ADP V4"
      color="white"
      description="Connect toi avec ton Adresse Mail MariaBird V3"
      premotto="Un Panel sortie du FUTURE"
      motto="ADP MARIABIRD V4"
      image={bgSignIn}
    >
      <VuiBox component="form" role="form" onSubmit={handleSubmit}>
      {error && (
        <div style={errorStyles}>
          {error}
        </div>
      )}
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Email
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius={borders.borderRadius.lg}
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput 
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="monmail@mariabirdv3.net" fontWeight="500" />
          </GradientBorder>
        </VuiBox>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Password
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle
            )}
          >
            <VuiInput
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password Account MariaBird V3"
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox mt={4} mb={1}>
          <VuiButton type="submit"color="info" fullWidth>
            LOGIN
          </VuiButton>
        </VuiBox>
      </VuiBox>
    </CoverLayout>
  );
}

export default SignIn;
