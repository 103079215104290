import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Grid, Typography } from "@mui/material";
import VuiBox from "components/VuiBox";

const ServerDetailsCard = ({ serverId }) => {
  const [serverDetails, setServerDetails] = useState(null);

  const api_key = 'API_vkHlW2ZgqbtNNVvl8tTc4s7YsXQgfMga';
  const client_key = 'CK_lpaOIX5ciZ4MsmDJawGtGXbFF9Bq4mNP';

  useEffect(() => {
    const fetchServerDetails = async () => {
      try {
        const response = await axios.get(`https://apiop.mariabird.net/server/info/${serverId}`, {
          headers: {
            'Api-Key': api_key,
            'Client-Key': client_key,
            'X-Pretty-JSON': '1',
            'Access-Control-Allow-Origin': '*'
          }
        });
        setServerDetails(response.data.response);
      } catch (error) {
        console.error('Error fetching server details:', error);
      }
    };

    fetchServerDetails();
  }, [serverId]);

  if (!serverDetails) {
    return <Typography>Chargement des données du server MariaBird.NET</Typography>;
  }

  return (
    <Card>
      <VuiBox p={3}>
        <Typography variant="h5">{serverDetails.hostname} - {serverDetails.server_id}</Typography>
        <Typography variant="body1">Status : {serverDetails.status}</Typography>
        <Typography variant="body1">IP : {serverDetails.ip_addr}</Typography>
        <Typography variant="body1">IP Alias : mariabird.net | mariagame.net</Typography>
        <Typography variant="body1">Location : {serverDetails.location}</Typography>
        <Typography variant="body1">Manager : {serverDetails.manager_type}</Typography>
        <Typography variant="h5">-- System Dédicated --</Typography>
        <Typography variant="body1">OS : {serverDetails.os}</Typography>
        <Typography variant="body1">Cpu : Intel(R) Xeon(R) CPU E5-1650 v3 @ 3.50GHz, 12 cores</Typography>
        <Typography variant="body1">Gpu : {serverDetails.gpu}</Typography>
        <Typography variant="body1">Ram : {serverDetails.ram}</Typography>
        <Typography variant="body1">Hdd/Ssd : {serverDetails.hdd}</Typography>
        <Typography variant="body1">Bandwidth : {serverDetails.bandwidth}</Typography>
      </VuiBox>
    </Card>
  );
};

export default ServerDetailsCard;
