import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const ServerStatsChart = ({ stats }) => {
    if (!stats || !stats.cpu_absolute || !stats.memory_bytes || !stats.memory_limit_bytes) {
        return <div>Loading Stats...</div>;
    }

    // Conversion de l'utilisation de la mémoire en pourcentage
    const memoryUsagePercent = (stats.memory_bytes / stats.memory_limit_bytes) * 100;

    // Options pour ApexCharts
    const chartOptions = {
        chart: {
            height: 350,
            type: "line",
            animations: {
                enabled: true,
                dynamicAnimation: {
                    speed: 1000,
                },
            },
            background: 'transparent',
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
        },
        xaxis: {
            type: "datetime",
        },
        yaxis: {
            labels: {
                formatter: (value) => `${value.toFixed(2)} %`,
            },
        },
        tooltip: {
            x: {
                format: "HH:mm:ss",
            },
        },
        colors: ["#FF4560", "#008FFB"],
    };

    // Initialiser les séries de données
    const [series, setSeries] = useState([
        {
            name: "CPU Usage",
            data: [],
        },
        {
            name: "Memory Usage",
            data: [],
        },
    ]);

    // Mise à jour des séries de données
    useEffect(() => {
        const interval = setInterval(() => {
            const time = new Date().getTime(); // Temps actuel en millisecondes
            const newSeries = series.map(s => {
                let newData = [...s.data, {
                    x: time,
                    y: s.name === "CPU Usage" ? stats.cpu_absolute : memoryUsagePercent,
                }];
                // Garder les données des 5 dernières minutes seulement
                newData = newData.filter(point => point.x > time - 30 * 1000);
                return { ...s, data: newData };
            });
            setSeries(newSeries);
        }, 1000); // Mettre à jour chaque seconde

        return () => clearInterval(interval);
    }, [stats.cpu_absolute, memoryUsagePercent]);

    return (
        <ReactApexChart
            options={chartOptions}
            series={series}
            type="line"
            height={350}
        />
    );
};

export default ServerStatsChart;
