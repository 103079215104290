import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import TerminalComponent from "layouts/terminal";
import { Box } from '@mui/material';
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
//import 'layouts/terminal/terminal.css';

function TerminalServers() {
  const [serverName, setServerName] = useState('');
  const { uid } = useParams();

  useEffect(() => {
    axios.get('https://api.mariabird.net/query')
      .then(response => {
        const server = response.data.find(s => s.uid === uid);
        if (server) {
          setServerName(server.name);
        }
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données des serveurs:', error);
      });
  }, [uid]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3.5}>
        <VuiBox mb={6}>
          <Grid container spacing="10px">
            <Grid item>
              <Box className="server-name">{serverName || 'Chargement...'}</Box>
              <TerminalComponent />
            </Grid>
          </Grid>
        </VuiBox>
      </VuiBox>
    </DashboardLayout>
  );
}

export default TerminalServers;
