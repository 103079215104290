import React, { useState, useEffect } from 'react';
import { useRive, useStateMachineInput } from '@rive-app/react-canvas';
import { Card } from "@mui/material";

const OneProviderInvoiceCard = () => {
  const STATE_MACHINE_NAME = 'GirlState'; // Remplacez par le nom de votre machine d'état dans Rive
  const HOVER_INPUT_NAME = 'MouseOver'; // Le nom de l'input pour le hover dans votre machine d'état

  const { rive, RiveComponent } = useRive({
    src: 'https://public.rive.app/community/runtime-files/5845-11463-curious-phone-girl.riv',
    stateMachines: STATE_MACHINE_NAME,
    autoplay: true,
  });

  const hoverInput = useStateMachineInput(rive, STATE_MACHINE_NAME, HOVER_INPUT_NAME);
  const [isHovered, setIsHovered] = useState(false);

  // Gérer l'entrée de la souris sur toute la fenêtre
  useEffect(() => {
    const handleMouseEnter = () => {
      hoverInput.value = true;
      setIsHovered(true);
    };

    const handleMouseLeave = () => {
      hoverInput.value = false;
      setIsHovered(false);
    };

    window.addEventListener('mouseenter', handleMouseEnter);
    window.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      window.removeEventListener('mouseenter', handleMouseEnter);
      window.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [hoverInput]);

  return (
    <Card sx={{
      height: "693px",
      width: "700px",
      py: "10px",
      backgroundColor: "rgba(255, 255, 255, 0.5)",
      backgroundBlendMode: "multiply",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <RiveComponent />
    </Card>
  );
};

export default OneProviderInvoiceCard;
