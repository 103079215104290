import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';
import { Terminal } from 'xterm';
import { Box } from '@mui/material';
import 'layouts/terminal/terminal.css';
import { FitAddon } from 'xterm-addon-fit';
import { SearchAddon } from 'xterm-addon-search';
import { SearchBarAddon } from 'xterm-addon-search-bar';
import { WebLinksAddon } from 'xterm-addon-web-links';

//import 'layouts/terminal/cssterm.css';
import PowerButton from 'layouts/terminalservers/component/PowerButton';
import StatsServers from 'layouts/terminalservers/component/StatsServers';
import ServerStatsChart from 'layouts/terminalservers/component/StatsChart';
import Command from 'layouts/terminalservers/component/Command';

const API_TOKEN = 'ptlc_sJFwRc6omnavmFx8fq0AIAJlqXFTVJcY7WDgq6WBLOU';

const TerminalComponent = () => {
  const { userDetails } = useAuth();
  const { uid } = useParams();
  const terminalRef = useRef(null);
  const wsRef = useRef(null);
  const [messageBuffer, setMessageBuffer] = useState([]);
  const [serverStats, setServerStats] = useState(null);
  const apiKey = userDetails?.identifier;

  

  useEffect(() => {
    const API_URL = `https://mariabird.net/api/client/servers/${uid}/websocket`;

    fetch(API_URL, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${API_TOKEN}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(data => {
      const { token, socket } = data.data;
      wsRef.current = new WebSocket(`${socket}?token=${token}`);
      setupWebSocket(token, socket);
    })
    .catch(error => {
      console.error('Error fetching WebSocket token:', error);
    });

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [uid]);

  const sendCommandToServer = (command) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify({ event: "send command", args: [command] }));
    } else {
      console.error('WebSocket connection is not open.');
    }
  };
  
  const setupWebSocket = (token, socketUrl) => {
    wsRef.current.onopen = () => {
      wsRef.current.send(JSON.stringify({ event: "auth", args: [token] }));
    };

    wsRef.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.event === 'console output' || message.event === 'status') {
        setMessageBuffer((prevBuffer) => [...prevBuffer, message.args[0]].slice(-100));
      } else if (message.event === 'stats') {
        setServerStats(JSON.parse(message.args[0]));
      } else if (message.event === 'token expiring' || message.event === 'token expired') {
        fetch(API_URL, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${API_TOKEN}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then(response => response.json())
        .then(data => {
          const newToken = data.data.token;
          wsRef.current.close();
          wsRef.current = new WebSocket(`${socketUrl}?token=${newToken}`);
          setupWebSocket(newToken, socketUrl);
        })
        .catch(error => {
          console.error('Error fetching new WebSocket token:', error);
        });
      }
    };

    wsRef.current.onclose = () => {};
    wsRef.current.onerror = (error) => {};
  };

  useEffect(() => {
    const xterm = new Terminal();
    terminalRef.current = xterm;
    xterm.open(document.getElementById('xterm'));
  
    const fitAddon = new FitAddon();
    xterm.loadAddon(fitAddon);
    const searchAddon = new SearchAddon();
    xterm.loadAddon(searchAddon);
    const searchBarAddon = new SearchBarAddon();
    xterm.loadAddon(searchBarAddon);
    const webLinksAddon = new WebLinksAddon();
    xterm.loadAddon(webLinksAddon);
    
    // Observer pour les modifications du DOM dans le terminal
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes) {
          mutation.addedNodes.forEach((node) => {
            if (node.classList && node.classList.contains('xterm-dom-renderer-owner')) {
              node.style.backgroundColor = 'blue';
            }
          });
        }
      });
    });
    
    observer.observe(document.querySelector('.xterm'), { childList: true, subtree: true });
    messageBuffer.forEach((line) => xterm.write(line + '\r\n'));
    
    // Ajuster la taille du terminal après le montage
    fitAddon.fit();
    
    // Ajuster la taille du terminal en cas de redimensionnement de la fenêtre
    const handleResize = () => {
      fitAddon.fit();
    };
    window.addEventListener('resize', handleResize);
  
    return () => {
      xterm.dispose();
      observer.disconnect();
      window.removeEventListener('resize', handleResize);
    };
  }, [messageBuffer]);
  

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="start">
      <Box flexGrow={1} id="xterm" className="xterm-container"></Box>
      <Box display="flex" flexDirection="column" className="control-panel">
        <Box className="stats-group">
          {PowerButton && (
            <Box className="power-button-group">
              <PowerButton ws={wsRef} />
            </Box>
          )}
          {serverStats && (
            <Box className="stats-server-box">
              <StatsServers stats={serverStats} />
            </Box>
          )}
          {serverStats && (
            <Box className="stats-chart-box">
              <ServerStatsChart stats={serverStats} />
            </Box>
          )}
        </Box>
      </Box>
      <Box className="command-container" xs={2} >
        <Command sendCommand={sendCommandToServer}/> 
      </Box> 
    </Box>
    
  );
};

export default TerminalComponent;
