import React, { createContext, useContext, useState, useEffect } from 'react';
import io from 'socket.io-client';

const AuthContext = createContext(null);

function getCookie(name) {
  const cookieArr = document.cookie.split(';');
  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].split('=');
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}

function setCookie(name, value, daysToExpire) {
  const maxAge = daysToExpire * 24 * 60 * 60;
  document.cookie = `${name}=${value}; path=/; max-age=${maxAge}; secure; samesite=strict`;
}

function deleteCookie(name) {
  setCookie(name, '', -1);
}


export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState(null);
  const [socket, setSocket] = useState(null);
  const [userStatuses, setUserStatuses] = useState({});
  const [debugMode, setDebugMode] = useState(false);
  const [token, setToken] = useState(null);


  const fetchUserDetails = async (token) => {
    try {
      const response = await fetch('https://api.mariabird.net/user-details', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des détails de l’utilisateur');
      }

      const userDetails = await response.json();
      setIsAdmin(Boolean(userDetails.isAdmin));
      setUserDetails(userDetails);
    } catch (error) {
      console.error('Erreur lors de la récupération des informations de l’utilisateur', error);
    }
  };

  useEffect(() => {
    const token = getCookie('adp_session');
    setIsLoading(true);
    if (token) {
      setIsAuthenticated(true);
      fetchUserDetails(token, setIsAdmin);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (isAuthenticated && userDetails && userDetails.id) {
      const newSocket = io('https://wssrt.mariabird.net', {
        query: { userId: userDetails.id, userName: userDetails.name }
      });
  
      newSocket.on('allStatuses', (statuses) => {
        //console.log('allStatuses received:', statuses); // Pour le débogage
        setUserStatuses(statuses);
      });
  
      newSocket.on('statusUpdated', (updatedStatuses) => {
        //console.log('statusUpdated received:', updatedStatuses); // Pour le débogage
        const newStatuses = {};
        Object.entries(updatedStatuses).forEach(([userId, userStatus]) => {
          newStatuses[userId] = userStatus.status;
        });
        setUserStatuses(newStatuses);
      });
  
      setSocket(newSocket);
  
      return () => {
        console.log('Disconnecting socket'); // Pour le débogage
        newSocket.disconnect();
      };
    }
  }, [isAuthenticated, userDetails, setUserStatuses, setSocket]);

  useEffect(() => {
    let inactivityTimer;

    const setToIdle = () => {
      if (socket && isAuthenticated && userDetails && userDetails.id) {
        socket.emit('updateStatus', { userId: userDetails.id, status: 'idle' });
      }
    };

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(setToIdle, 300000); // 5 minutes

      // Si l'utilisateur interagit, définir le statut sur "online"
      if (socket && isAuthenticated && userDetails && userDetails.id) {
        socket.emit('updateStatus', { userId: userDetails.id, status: 'online' });
      }
    };

    if (isAuthenticated) {
      window.addEventListener('mousemove', resetInactivityTimer);
      window.addEventListener('keydown', resetInactivityTimer);
      resetInactivityTimer(); // Réinitialiser le timer initialement
    }

    return () => {
      clearTimeout(inactivityTimer);
      window.removeEventListener('mousemove', resetInactivityTimer);
      window.removeEventListener('keydown', resetInactivityTimer);
    };
  }, [isAuthenticated, socket, userDetails]);

  const login = async (token) => {
    setCookie('adp_session', token, 30);
    setIsAuthenticated(true);
    await fetchUserDetails(token, setIsAdmin);
  };

  const logout = () => {
    deleteCookie('adp_session');
    setIsAuthenticated(false);
    setIsAdmin(false);
  };

  const updateDebugMode = async (newDebugMode) => {
    try {
      // Assurez-vous d'obtenir le jeton d'authentification nécessaire
      const token = getCookie('adp_session');
      const response = await fetch('https://api.mariabird.net/update/profile/debugmode', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ userId: userDetails.id, debugMode: newDebugMode }),
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la mise à jour du mode débogage');
      }

      const data = await response.json();
      setDebugMode(data.debugMode); // Mettre à jour l'état local avec la nouvelle valeur
    } catch (error) {
      console.error('Erreur lors de la mise à jour du mode débogage:', error);
    }
  };

  useEffect(() => {
    const tokenFromCookie = getCookie('adp_session');
    setIsLoading(true);
    if (tokenFromCookie) {
      setIsAuthenticated(true);
      setToken(tokenFromCookie); // Définir le token à partir du cookie
      fetchUserDetails(tokenFromCookie);
    }
    setIsLoading(false);
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, isAdmin, isLoading, login, logout, socket, userStatuses, userDetails, debugMode, setDebugMode, token }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
