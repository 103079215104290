import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSwitch from "components/VuiSwitch";
import debugSound from "./warring.mp3";

function PlatformSettings() {
  const [debug, setDebugMode] = useState(false);
  const [api, setAPIMode] = useState(true);
  const [rien, setRien] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    // Vérifiez si le mode de débogage est activé
    if (debug) {
      // Jouez le son lorsque le mode de débogage est activé
      const audio = new Audio(debugSound);
      audio.play();
    }
  }, [debug]);

  const handleDebugChange = () => {
    setDebugMode(!debug);
    setOpenSnackbar(!debug);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
    <Card sx={{ minHeight: "490px", height: "100%" }}>
      <VuiBox mb="26px">
        <VuiTypography variant="lg" fontWeight="bold" color="white" textTransform="capitalize">
          Application Settings
        </VuiTypography>
      </VuiBox>

      {debug && (
        <VuiBox mb="20px">
          <Alert severity="warning">
            Attention le Debug Mode es Activé
          </Alert>
        </VuiBox>
      )}

      <VuiBox lineHeight={1.25}>
        <VuiTypography variant="xxs" fontWeight="medium" mb="20px" color="text" textTransform="uppercase">
          Account
        </VuiTypography>

        <VuiBox display="flex">
          <VuiBox mt={0.25}>
            <VuiSwitch color="info" checked={rien} onChange={() => setRien(!rien)} />
          </VuiBox>
          <VuiBox width="80%" ml={2}>
            <VuiTypography variant="button" fontWeight="regular" color="text">
              Notification Push ( Web_Version)
            </VuiTypography>
          </VuiBox>
        </VuiBox>

        <VuiBox mb="6px">
          <VuiTypography variant="xxs" fontWeight="medium" color="text" textTransform="uppercase">
            Application
          </VuiTypography>
        </VuiBox>

        <VuiBox display="flex">
          <VuiBox mt={0.25}>
            <VuiSwitch color="warning" checked={debug} onChange={handleDebugChange} />
          </VuiBox>
          <VuiBox width="80%" ml={2}>
            <VuiTypography variant="button" fontWeight="regular" color="warning">
              Debug Mode
            </VuiTypography>
          </VuiBox>
        </VuiBox>

        <VuiBox display="flex">
          <VuiBox mt={0.25}>
            <VuiSwitch color="info" checked={api} onChange={() => setAPIMode(!api)} />
          </VuiBox>
          <VuiBox width="80%" ml={2}>
            <VuiTypography variant="button" fontWeight="regular" color="text">
              API (Action)
            </VuiTypography>
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </Card>
    <Snackbar
        open={openSnackbar}
        autoHideDuration={12000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="warning" sx={{ width: '100%' }}>
        Attention le Debug Mode es Activé
        </Alert>
      </Snackbar>
    </>
    // Impossible d'activer le Debug Mode ! (Cette Fonction n'est pas encore Disponible.)
    // Debug mode is activated. This may affect performance and display additional information for debugging.
  );
}

export default PlatformSettings;
