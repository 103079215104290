import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import VuiBox from 'components/VuiBox';
import { Card, CardContent, Typography } from '@mui/material';

const EditUser = () => {
  const { q, u } = useParams();
  const [userData, setUserData] = useState(null);
  const apiKey = 'ptla_17T1VO9epJNWSL9H39j7SEf9dT4qWepFAWuHre3UPZk';

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        let url = '';
        if (q === 'adp') {
          url = `https://api.mariabird.net/allusers/${u}`;
          // Ajoutez la clé API ici si nécessaire
        } else if (q === 'mbc') {
          url = `https://mariabird.net/api/application/users/${u}`;
          headers = { Authorization: `Bearer ${apiKey}` };
        }

        const response = await axios.get(url);
        setUserData(response.data.attributes);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [q, u]);

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', options).replace(/(\d+)\/(\d+)\/(\d+),/, '$3/$2/$1');
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox title="Modifier l'utilisateur">
        {userData && (
          <Card>
            <CardContent>
              <Typography variant="h5">Informations de l'utilisateur</Typography>
              <Typography>ID de l'utilisateur : {userData.id}</Typography>
              <Typography>Nom d'utilisateur : {userData.username}</Typography>
              <Typography>Email : {userData.email}</Typography>
              <Typography>Prénom : {userData.first_name}</Typography>
              <Typography>Nom de famille : {userData.last_name}</Typography>
              <Typography>Langue : {userData.language}</Typography>
              <Typography>Administrateur principal : {userData.root_admin ? 'Oui' : 'Non'}</Typography>
              <Typography>Tableau de bord : {userData.dashboard}</Typography>
              <Typography>2FA : {userData['2fa'] ? 'Activé' : 'Désactivé'}</Typography>
              <Typography>Date de création : {formatDate(userData.created_at)}</Typography>
              <Typography>Date de mise à jour : {formatDate(userData.updated_at)}</Typography>
            </CardContent>
          </Card>
        )}
      </VuiBox>
    </DashboardLayout>
  );
};

export default EditUser;
