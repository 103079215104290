import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';

const Logout = () => {
  const { logout } = useAuth();
  const history = useHistory();

  useEffect(() => {
    document.cookie = 'adp_session=; Max-Age=0';
    logout();

    history.push('/login');
  }, []);

  return (
    <div>Vous êtes déconnecté</div>
  );
};

export default Logout;
