import React, { useState, useEffect } from 'react';
import { Card, Skeleton } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material'; // Import des icônes de flèches
import io from 'socket.io-client';

const NetworkStats = () => {
  const [interfaceStats, setInterfaceStats] = useState({});

  useEffect(() => {
    const socket = io('https://wsstats.mariabird.net/');

    socket.on('serverStats', (data) => {
      if (data.networkInfo) {
        const extractedStats = extractInterfaceStats(data.networkInfo);
        setInterfaceStats(extractedStats);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const extractInterfaceStats = (networkInfo) => {
    const requiredInterfaces = ['eno3', 'docker0', 'pterodactyl0'];

    const interfaceStats = {};
    requiredInterfaces.forEach((interfaceName) => {
      const interfaceData = networkInfo[interfaceName];
      if (interfaceData) {
        interfaceStats[interfaceName] = {
          rx: interfaceData.reduce((acc, { rx_bytes }) => acc + rx_bytes, 0),
          tx: interfaceData.reduce((acc, { tx_bytes }) => acc + tx_bytes, 0)
        };
      }
    });
    return interfaceStats;
  };

  const renderNetworkStats = () => {
    return Object.keys(interfaceStats).map((interfaceName) => {
      const stats = interfaceStats[interfaceName];
      return (
        <VuiBox key={interfaceName} mt="20px">
          <VuiTypography variant="h6">{interfaceName}</VuiTypography>
          <VuiTypography variant="body1">RX: {stats.rx} bytes {stats.rx_trend > 0 ? <ArrowUpward /> : <ArrowDownward />}</VuiTypography>
          <VuiTypography variant="body1">TX: {stats.tx} bytes {stats.tx_trend > 0 ? <ArrowUpward /> : <ArrowDownward />}</VuiTypography>
        </VuiBox>
      );
    });
  };

  return (
    <Card sx={{ height: '400px', width: '700px', position: 'absolute', top: 200, right: 900, zIndex: 1000 }}>
      <VuiBox display="flex" flexDirection="column" justifyContent="space-around" alignItems="center" sx={{ height: '100%', p: '20px' }}>
        <VuiTypography variant="lg" color="white" fontWeight="bold" mt="20px" mb="20px">
          Network Statistics
        </VuiTypography>
        {Object.keys(interfaceStats).length > 0 ? renderNetworkStats() : <Skeleton variant="text" width={200} height={50} animation="wave" />}
      </VuiBox>
    </Card>
  );
};

export default NetworkStats;
