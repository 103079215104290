import React from "react";
import { Card } from "@mui/material";
import VuiBox from "components/VuiBox";
import servergif from "assets/images/servercard.gif";

const ServerCard = () => {
  return (
    <Card sx={{
      height: "693px",
      width: "700px",
      py: "10px",
      backgroundImage: `url(${servergif})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundColor: "rgba(255, 255, 255, 0.5)",
      backgroundBlendMode: "multiply",
    }}>
    </Card>
  );
};

export default ServerCard;
