import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';
import { Grid } from '@mui/material';
import VuiBox from 'components/VuiBox';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import ServerStats from 'layouts/dashboard/components/ServerStats';
import NetworkStats from 'components/Networks';
import NodesTracking from 'layouts/dashboard/components/NodesTracking/NodesTracking';

const Admin = () => {
  const { userDetails } = useAuth();

  if (!userDetails || !userDetails.isAdmin) {
    return <Redirect to="/" />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={51.5}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ServerStats />
        </Grid>
        <Grid item xs={12} md={6}>
          <NetworkStats />
        </Grid>
        <Grid item xs={12} md={6}>
          <NodesTracking />
        </Grid>
      </Grid>
      </VuiBox>
    </DashboardLayout>
  );
};

export default Admin;
