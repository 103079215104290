import React, { useState } from "react";
import { useAuth } from 'context/AuthContext';
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

function ProfileUpdater() {
  const { userDetails } = useAuth();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState(userDetails?.email || "");
  const [openDialog, setOpenDialog] = useState(false);
  const apiKey = userDetails?.identifier;
  const adpKey = userDetails?.adpkey;

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleUpdateProfile = async () => {
    try {
      const response = await fetch('https://api.mariabird.net/update/profile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password, email }),
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la mise à jour du profil');
      }

      const data = await response.json();
      console.log("Profile Updated:", data);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du profil:', error);
    }
  };

  const handleResetApiKey = async () => {
    try {
      const response = await fetch('https://api.mariabird.net/update/apikey', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Incluez d'autres en-têtes si nécessaire, par exemple un en-tête d'autorisation
        },
        body: JSON.stringify({ userId: userDetails.id }),
      });
  
      if (!response.ok) {
        throw new Error('Erreur lors de la réinitialisation de la clé API');
      }
  
      const data = await response.json();
      console.log("API Key Reset:", data);
      // Mettez à jour l'état de l'API key ici si nécessaire
      setOpenDialog(false);
    } catch (error) {
      console.error('Erreur lors de la réinitialisation de la clé API:', error);
    }
  };
  

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Card sx={{ minHeight: "490px", height: "100%" }}>
      <VuiBox mb="26px">
        <VuiTypography variant="lg" fontWeight="bold" color="white" textTransform="capitalize">
          Update Profile
        </VuiTypography>

        <VuiBox my="20px">
          <TextField
            fullWidth
            label=""
            placeholder="New Password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            size="medium"
          />
          <TextField
            fullWidth
            label=""
            placeholder="Email"
            type="email"
            value={email}
            onChange={handleEmailChange}
            sx={{ mt: 2 }}
          />
        </VuiBox>

        <Button variant="outlined" color="primary" onClick={handleUpdateProfile}>
          Update
        </Button>

        <VuiBox my="20px">
          <TextField
            label="API Key - ADP V4"
            value={adpKey}
            InputProps={{
              readOnly: true,
            }}
          />
        </VuiBox>
        <VuiBox my="20px">
          <TextField
            label="API Key - MariaBied Classic"
            value={apiKey}
            InputProps={{
              readOnly: true,
            }}
          />
        </VuiBox>
      </VuiBox>

      <VuiBox>
          <Button variant="outlined" color="error" onClick={handleClickOpen}>
            Reset API Key
          </Button>
        </VuiBox>

      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Reset API Key"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Êtes-vous sûr de vouloir réinitialiser votre clé API ? Cette action ne peut pas être annulée.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleResetApiKey} autoFocus>
            Reset
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default ProfileUpdater;
