import React, { useState, useEffect } from 'react';
import axios from 'axios';
// @mui material components
import Grid from "@mui/material/Grid";
//import Icon from "@mui/material/Icon";
//import { Card, LinearProgress, Stack } from "@mui/material";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
//import VuiTypography from "components/VuiTypography";
//import VuiProgress from "components/VuiProgress";
import HeaderOP from 'components/HeaderOneProvider/HeaderOP';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from "examples/Footer";
//import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
//import linearGradient from "assets/theme/functions/linearGradient";

// Vision UI Dashboard React base styles
//import typography from "assets/theme/base/typography";
import colors from "assets/theme/base/colors";

// Dashboard layout components
import ServerCard from './component/serverscard';
import ServerDetailsCard from './component/serverdetails';
//import Projects from "layouts/dashboard/components/Projects";
//import OrderOverview from "layouts/dashboard/components/OrderOverview";
//import SatisfactionRate from "layouts/dashboard/components/SatisfactionRate";
//import ReferralTracking from "layouts/dashboard/components/ReferralTracking";
//import Terminal from "layouts/terminal/index.js";

// React icons
//import { IoIosRocket } from "react-icons/io";
//import { IoGlobe } from "react-icons/io5";
//import { IoBuild } from "react-icons/io5";
//import { IoWallet } from "react-icons/io5";
//import { IoDocumentText } from "react-icons/io5";
//import { FaShoppingCart } from "react-icons/fa";

// Data
//import LineChart from "examples/Charts/LineCharts/LineChart";
//import BarChart from "examples/Charts/BarCharts/BarChart";
//import { lineChartDataDashboard } from "layouts/dashboard/data/lineChartData";

//import { lineChartDataServerStats } from "./data/lineChartServerData";
//import { lineChartOptionsServerStats } from "./data/lineChartServerOptions";
//import { lineChartOptionsServerStats, lineChartDataServerStats } from '/data/lineChartServerOptions';


//import { lineChartOptionsDashboard } from "layouts/dashboard/data/lineChartOptions";
//import { barChartDataDashboard } from "layouts/dashboard/data/barChartData";
//import { barChartOptionsDashboard } from "layouts/dashboard/data/barChartOptions";

const OneProvider = () => {
  const [servers, setServers] = useState([]);
  const [selectedServer, setSelectedServer] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const api_key = 'API_vkHlW2ZgqbtNNVvl8tTc4s7YsXQgfMga';
  const client_key = 'CK_lpaOIX5ciZ4MsmDJawGtGXbFF9Bq4mNP';

  // Fonction pour récupérer la liste des serveurs
  const fetchServers = async () => {
    try {
      const response = await axios.get('https://apiop.mariabird.net/server/list/', {
        headers: {
          'Api-Key': api_key,
          'Client-Key': client_key,
          'X-Pretty-JSON': '1',
          'Access-Control-Allow-Origin': '*'
        }
      });
      setServers(response.data.response.servers);
    } catch (error) {
      console.error('Error fetching servers:', error);
    }
  };

  // Fonction pour récupérer les détails d'un serveur
  const fetchServerDetails = async (serverId) => {
    try {
      const response = await axios.get(`https://apiop.mariabird.net/server/info/${serverId}`, {
        headers: {
          'Api-Key': api_key,
          'Client-Key': client_key,
          'X-Pretty-JSON': '1',
          'Access-Control-Allow-Origin': '*'
        }
      });
      setSelectedServer(response.data.response);
    } catch (error) {
      console.error('Error fetching server details:', error);
    }
  };

  // Fonction pour récupérer la liste des factures
  const fetchInvoices = async () => {
    try {
      const response = await axios.get('https://apiop.mariabird.net/invoices', {
        headers: {
          'Api-Key': api_key,
          'Client-Key': client_key,
          'X-Pretty-JSON': '1',
          'Access-Control-Allow-Origin': '*'
        }
      });
      setInvoices(response.data.response);
    } catch (error) {
      console.error('Error fetching invoices:', error);
    }
  };

  // À utiliser pour initialiser les données au chargement du composant
  useEffect(() => {
    fetchServers();
    fetchInvoices();
  }, []);

  return (
    <DashboardLayout>
      
      <HeaderOP />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={5} xl={6} justifyContent="center">
              <ServerCard />
            </Grid>
            <Grid item xs={12} lg={6} xl={5} justifyContent="center">
              <ServerDetailsCard serverId="508219" />
            </Grid>
          </Grid>
        </VuiBox>
      </VuiBox>
    </DashboardLayout>
  );
  
};

export default OneProvider;
