import React from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';

const StatsServers = ({ stats }) => {

  const cpuUsagePercent = Math.min(stats.cpu_absolute, 1000).toFixed(2);
  const memoryUsedInGB = (stats.memory_bytes / (1024 ** 3)).toFixed(2);
  const memoryLimitInGB = (stats.memory_limit_bytes / (1024 ** 3)).toFixed(2);
  const memoryUsedPercent = (parseFloat(memoryUsedInGB) / parseFloat(memoryLimitInGB)) * 100;


  const formatUptime = (uptimeMillis) => {
    const seconds = uptimeMillis / 1000;
    const days = Math.floor(seconds / (24 * 3600));
    const hours = Math.floor((seconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secondsRemain = Math.floor(seconds % 60);

    let formattedUptime = '';
    if (days > 0) formattedUptime += `${days}d `;
    if (hours > 0 || days > 0) formattedUptime += `${hours}h `;
    formattedUptime += `${minutes}m ${secondsRemain}s`;

    return formattedUptime;
  };

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle2">State: {stats.state}</Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle2">Uptime: {formatUptime(stats.uptime)}</Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle2">CPU Usage: {cpuUsagePercent}%</Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle2">Memory Usage: {memoryUsedInGB} Go / {memoryLimitInGB} Go</Typography>
      </Box>
    </Box>
  );
};

export default StatsServers;
