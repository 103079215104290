import React from "react";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import colors from "assets/theme/base/colors";
import './styles.css';

import GirlsCard from "components/Girls/Girls";
import ServerStats from "./components/ServerStats";
import ServiceStatus from "components/ServiceStatus/ServiceStatus";
//import NetworkStats from "./components/Networks";

function Dashboard() {
  const { gradients } = colors;
  const { cardContent } = gradients;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={8.2}>
        <GirlsCard/>
        <ServerStats/>

      </VuiBox>
    </DashboardLayout>
  );
}

export default Dashboard;
