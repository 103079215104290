import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertComponent = () => {
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const errorSound = new Audio('audio/error.mp3'); // Remplacez /path/to/error-sound.mp3 par le chemin de votre fichier audio

  useEffect(() => {

    errorSound.volume = 0.02;
    const interceptor = axios.interceptors.response.use(
      response => response,
      error => {
        // Gestion des erreurs de réponse réseau
        if (error.response) {
          const status = error.response.status;
          if (status === 400 || status === 404 || status === 500 || status === 503 || status === 429 || status === 401 || status === 502 || status === 503) {
            setErrorMessage(`Erreur réseau: ${status} ${error.response.statusText}`);
            setOpen(true);
            errorSound.play(); // Jouer le son en cas d'erreur réseau
          }
        } else if (error.message === 'Network Error') {
          setErrorMessage('Erreur réseau: La requête a été bloquée ou le serveur est inaccessible.');
          setOpen(true);
          errorSound.play(); // Jouer le son en cas d'erreur réseau
        }
        return Promise.reject(error);
      }
    );

    // Suppression de l'intercepteur lors du démontage du composant
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [errorSound]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};

export default AlertComponent;
