import React, { useContext, useEffect, useState } from 'react';
import { useAuth } from 'context/AuthContext';
import axios from 'axios';
import md5 from 'blueimp-md5';
import './UserStatus.css';

const UsersStatus = () => {
    const { userStatuses } = useAuth();
    const [usersData, setUsersData] = useState({});

  const getGravatarUrl = (email) => {
    const normalizedEmail = email.trim().toLowerCase();
    const md5Hash = md5(normalizedEmail);
    return `https://www.gravatar.com/avatar/${md5Hash}?s=40`;
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get('https://api.mariabird.net/allusers');
        const users = response.data.reduce((acc, user) => {
          acc[user.id] = { ...user };
          return acc;
        }, {});
        setUsersData(users);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUserData();
  }, []);

  return (
    <div>
      <ul className="user-status-list">
        {Object.entries(usersData).map(([userId, userDetails]) => {
          const userStatus = userStatuses[userId];
          const status = userStatuses[userId] ? userStatuses[userId][1] : "offline";
          const statusClass = `user-status-indicator ${status}`;
  
          //console.log(`User ID: ${userId}, Status: ${status}, Class: ${statusClass}`);
  
          return (
            <li key={userId} className="user-status-item">
              <div className="user-status-avatar">
                <img src={getGravatarUrl(userDetails.email)} alt={`${userDetails.name} avatar`} />
                <span className={statusClass}></span>
              </div>
              <div className="user-status-name">
                {userDetails.name}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
  
};

export default UsersStatus;
