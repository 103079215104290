
import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgSignIn from "assets/images/signInImage.png";
function SignUp() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    let timer;
    if (showError) {
      timer = setTimeout(() => {
        setShowError(false); // Cela cachera la notification après 3 secondes
      }, 15000);
    }
    // Fonction de nettoyage pour effacer le timer
    return () => clearTimeout(timer);
  }, [showError]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    try {
      const response = await fetch('https://api.mariabird.net/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, password }),
      });
      if (response.ok) {
        console.log('Inscription réussie');
        history.push('/login');
      } else {
        console.log('Échec de la connexion');
        setError('Un utilisateur avec cet email existe déjà.');
      }
    } catch (error) {
      console.error('Erreur de connexion', error);
      setError('Une erreur s’est produite. Veuillez réessayer.');
    }
  };

    // Error Style //
    const errorStyles = {
      fontSize: '0.8em',
      position: 'absolute',
      top: '15%',
      right: '26px',
      backgroundColor: 'red',
      color: 'white',
      padding: '10px',
      borderRadius: '5px',
      zIndex: 1000,
      maxWidth: '300px',
    };
    

  return (
    <CoverLayout
      title="Bienvenue ADP V4"
      description="Inscrivez-vous avec votre adresse e-mail"
      premotto="Un Panel sortie du FUTURE"
      motto="ADP MARIABIRD V4"
      image={bgSignIn}
    >
      <VuiBox component="form" role="form" onSubmit={handleSubmit}>
      {error && (
        <div style={errorStyles}>
          {error}
        </div>
      )}
        {/* Champ Name */}
        <VuiBox mb={2}>
          <VuiInput 
            placeholder="Username"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </VuiBox>

        {/* Champ Email */}
        <VuiBox mb={2}>
          <VuiInput 
            type="email"
            placeholder="Votre email..."
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </VuiBox>

        {/* Champ Password */}
        <VuiBox mb={2}>
          <VuiInput 
            type="password"
            placeholder="Votre mot de passe..."
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </VuiBox>

        <VuiBox mt={4} mb={1}>
          <VuiButton color="info" fullWidth type="submit">
            S'INSCRIRE
          </VuiButton>
        </VuiBox>

        <VuiBox mt={3} textAlign="center">
          <VuiTypography variant="button" color="text" fontWeight="regular">
            Vous avez déjà un compte?{" "}
            <VuiTypography component={Link} to="/login" variant="button" color="white" fontWeight="medium">
              Se connecter
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    </CoverLayout>
  );
}

export default SignUp;
